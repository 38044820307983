import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Unplanned Leave">
    <Hero title="Unplanned Leave" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard title="Introduction">
          <EmberCardSection>
            <Stack>
              <Text>
                Sometimes you will be unable to work due to being sick, because
                of a family emergency or due to something like jury duty. This
                policy sets out what counts as unplanned leave, how you should
                notify us that you need to take unplanned leave and how you will
                be paid.
              </Text>
              <Text>
                Note that this policy does not cover any requests to use annual
                leave at short notice, for reasons such as attending a funeral.
                These are covered under the{" "}
                <TextLink href="../annual-leave">Annual Leave Policy</TextLink>.
                It also doesn't cover parental leave which is covered by our{" "}
                <TextLink href="../parental-leave">
                  Parental Leave Policy
                </TextLink>
                .
              </Text>
              <Text>
                If you have any doubts about whether you are eligible for leave
                then talk to us. There may be some exceptional circumstances
                that we have not considered when writing this policy and we're
                happy to look into those on a case-by-case basis.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Valid Reasons">
          <EmberCardSection>
            <Stack>
              <Heading type="title5">Sick Leave</Heading>
              <Text>
                The most common reason for taking unplanned leave is if you are
                feeling ill. This might just be a nasty cold or minor injury but
                it could also be for a serious or long-term issue, including
                reasons related to mental health and scheduled treatment.
              </Text>
              <Text>
                If it's something short-term, then just let us know what's
                happened and when you hope to be back. We'll work to cover your
                shifts and we hope you'll be back on your feet as soon as
                possible.
              </Text>
              <Text>
                For longer-term issues that need proactive management, it's
                helpful to make your manager aware of the issue in advance, so
                that we understand what we can do to help.
              </Text>
              <Heading type="title5">Emergency Leave for Dependents</Heading>
              <Text>
                You can take leave to care for a dependent in an emergency. A
                dependent can include a spouse, partner, child, grandchild,
                parent or anyone else who depends on you for care.
              </Text>
              <Text>
                We follow the{" "}
                <TextLink
                  external
                  href="https://www.gov.uk/time-off-for-dependants"
                >
                  government guidance
                </TextLink>{" "}
                on time off for emergencies related to family and dependents.
                This sets out what counts as an emergency. Note that it excludes
                occassions where you knew about the situation beforehand and
                could have reasonably planned for it (e.g. by requesting short
                notice annual leave under our compassionate leave policy).
              </Text>
              <Text>
                We try to be fair in deciding whether someone falls under this
                definition. For instance, if there's a life-threatening
                emergency involving a loved one then we'll of course give you
                leave, whether or not they are technically a dependent.
              </Text>
              <Text>
                However, please be aware there are cases where we need to say
                no. For instance, we won't generally be able to give you
                emergency leave to attend the birth of a grandchild, unless you
                are the parent's primary care-giver. Instead, we would ask you
                to arrange shift swaps if you wanted additional time off or to
                have booked additional holiday in advance around the due date.
              </Text>
              <Heading type="title5">Other Reasons</Heading>
              <Text>
                There are some other valid reasons for taking unplanned leave.
                One of the most common is jury duty, which you are legally
                required to do. We're happy to accomodate this but please let us
                know as soon as you get told you have been called up.
              </Text>
              <Text>
                Another is where you are not fit to work for some reason other
                than being sick. For instance, if you have had serious problems
                sleeping and cannot safely drive. We would never ask you to work
                where not safe so will give you unplanned leave. If this is a
                regular occurence then your manager may set up a discussion with
                you to figure out if there is a deeper issue that needs solved.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Taking Unplanned Leave">
          <EmberCardSection>
            <Stack>
              <Text>
                The most important thing is to contact us as soon as possible so
                we can make alternative arrangements. You should do this by
                phoning the driver number, rather than just sending a chat
                message, so we can find emergency cover.
              </Text>
              <Text>
                If you can, please give us a reasonable estimate of how long you
                expect to be unable to work. We understand this may change –
                e.g. you can't know for sure when you will be feeling better –
                so we just ask that you keep us updated with as much notice as
                possible.
              </Text>
              <Text>
                Covering unplanned leave places a significant burden on the rest
                of the team so we need to make sure it's for one of the reasons
                listed above. We may ask for evidence of this reason.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Pay During Unplanned Leave">
          <EmberCardSection>
            <Stack>
              <Text>
                If you're feeling sick or you're dealing with an emergency, we
                don't want you to be worrying about pay. Therefore, we will
                provide full pay for up to four weeks of unplanned leave per
                year. This goes well beyond the legal minimums. Beyond this
                period, you'll be paid according to the statutory minimums.
              </Text>
              <Text>
                The actual number of days of enhanced pay this works out as will
                depend on your contract:
              </Text>
              <List>
                <ListItem>
                  Full-Time Driver: If you're a full-time driver with a 40 hour
                  per week contract, four weeks of enhanced pay works out as 160
                  hours or 20 eight-hour days
                </ListItem>
                <ListItem>
                  Part-Time Driver: If you're a part-time driver, the amount of
                  enhanced leave will be reduced by pro-rata amount based on
                  your contracted hours. For instance, if you have a contract
                  for 24 hours per week then four weeks of enhanced pay works
                  out as 96 hours or 12 eight-hour days.
                </ListItem>
                <ListItem>
                  Relief Driver: If you're a relief driver on a zero-hour
                  contract, you will only get the statutory sick pay (SSP). In
                  many cases, the SSP rules mean you will not be eligible
                  because you will not have worked enough hours recently or you
                  will not have enough scheduled days of work over the period of
                  sickness (qualifying days). Ask us if you think you are
                  eligible.
                </ListItem>
              </List>
              <Text>
                For each day of sick leave taken, we will count it as if you'd
                worked for eight hours that day or the actual amount of time you
                were scheduled to work if it's different (e.g. if you were due
                to work a half-shift). This affects the final calculations in
                the quarterly pay rebalancing.
              </Text>
              <Text>
                Note that our enhancement includes any statutory pay you may be
                entitled to. In some cases, such as with jury duty, this may
                require you to claim an amount directly from the government
                which we will then top up.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
